<template>
  <el-dialog
    :title="dialogTitle"
    :visible.sync="riskVisible"
    :close-on-click-modal="false"
    @close="dialogClose"
    width="570px"
  >
    <el-table :data="tableRiskData" style="width: 100%" size="medium" border v-loading="tableRiskLoading">
      <el-table-column prop="name" :label="title" align="center">
        <template slot-scope="{ row }">
          <el-input v-model="row.name" placeholder="点击输入"></el-input>
        </template>
      </el-table-column>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="150" align="center">
        <template slot-scope="{ row, $index }">
          <span class="view-details" @click="deleteConfig(row.id, $index)" style="color:#F56C6C">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div slot="footer" class="footer-btn-grounp">
      <div class="add-btn" @click="handelConfigHidden">添加配置项</div>
      <div>
        <el-button @click="handelClose">取消</el-button>
        <el-button type="primary" @click="handleDangerConfig" :loading="btnLoading">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  addConfigurationDetailSortList,
  getConfigurationDetailSortList,
  deleteConfigurationDetail,
} from 'api/super-admin/common';
import { getItem } from '@/utils/storage.js';
export default {
  name: 'ConfigHiddenDialog',
  props: {
    dialogVisible: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data() {
    return {
      title: '',
      btnLoading: false,
      riskVisible: this.dialogVisible,
      tableRiskLoading: false,
      tableRiskData: [],
      configId: '' //所属配置项id
    };
  },
  methods: {
    initTabelConfigFunc(id, title) {
      //父祖传访问子组件传入的值
      this.configId = id;
      this.title = title;
      this.getConfigurationDetailSortList();
    
    },
    handelConfigHidden() {
      //添加项
      let obj = {
        name: '',
        configId: this.configId,
        tenantId: getItem('orgId'),
      };
      this.tableRiskData.push(obj);
    },
    deleteConfig(id, index) {
      //删除
      this.$confirm('确认是否删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        if (id) {
          this.deleteConfigurationDetail(id);
        } else {
          this.tableRiskData.splice(index, 1);
        }
      });
    },
    handleDangerConfig() {
      //确定
      if (!this.fillValidateFunc()) {
        return false;
      }
      this.addConfigurationDetailSortList();
    },
    fillValidateFunc() {
      //判断每行数据是否都输入，返回Boolean值
      let flag = true;
      this.tableRiskData.find(item => {
        if (!item.name) {
          this.$message.warning('请填写完整');
          flag = false;
        }
      });
      return flag;
    },
    handelClose() {
      this.$emit('update:dialogVisible', false);
    },
    dialogClose() {
      this.$emit('update:dialogVisible', false);
    },
    //添加/修改配置项的具体配置接口
    addConfigurationDetailSortList() {
      this.btnLoading = true;
      addConfigurationDetailSortList({ configurationDetailParamList: this.tableRiskData })
        .then(res => {
          if (res.code === 200) {
            this.btnLoading = false;
            this.$emit('update:dialogVisible', false);
            this.$message.success('操作成功');
          }
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    //配置项的具体配置列表接口
    getConfigurationDetailSortList() {
      this.tableRiskLoading = true;
      //配置项的具体配置列表
      getConfigurationDetailSortList({ tenantId: getItem('orgId'), configId: this.configId }).then(res => {
        if (res.code === 200) {
          this.tableRiskLoading = false;
          this.tableRiskData = res.data;
        }
      });
    },
    //删除接口
    deleteConfigurationDetail(id) {
      //配置项的具体配置列表
      deleteConfigurationDetail({ id }).then(res => {
        if (res.code === 200) {
          this.btnLoading = false;
          this.$message.success('删除成功');
          this.getConfigurationDetailSortList();
        }
      });
    },
   
  },

  watch: {
    dialogVisible(val) {
      this.riskVisible = val;
    }
  },

  computed: {
    dialogTitle() {
      return this.title + '配置';
    }
  }
};
</script>

<style scoped>
.footer-btn-grounp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
}
.add-btn {
  width: 94px;
  height: 32px;
  border: 1px solid #005aab;
  border-radius: 2px;
  text-align: center;
  line-height: 32px;
  color: #005aab;
  font-size: 14px;
  cursor: pointer;
}
</style>
